import { useState } from "react";
// import { Render } from '@react-email/render' 
// import nodemailer from 'nodemailer';
// import api from '../../controller/email.json'
// import { EventEmitter } from "stream";

import Container from "../layout/Container";
import Input from "../form/Input";
import Message from "../layout/Message";
import Loading from "../layout/Loading";

import Logo from "../../img/Logo.png";
import styles from "./CanSau.module.css";
import TextArea from "../form/TextArea";
import axios from "axios";
// import background from '../../img/FundoSaude.jpg'

function Denuncia() {

    const [Nome, SetNome] = useState('');
    const [Msg, SetMsg] = useState('');
    const [message, setMessage] = useState({ type: '', msg: '' })
    const [loading, setLoading] = useState(false)

    const API_SERVER = process.env.REACT_APP_API_SERVER
    const API_PORT = process.env.REACT_APP_API_PORT
    const API_HTTP = process.env.REACT_APP_API_HTTP
    const API_BASE = process.env.REACT_APP_API_BASEURL

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        setMessage('');

        if (!Msg.trim() || Msg.trim().length <= 3) {
            setMessage({ type: 'error', msg: 'Campo "Mensagem" não pode ser vazio ou ter menos de 3 caracteres!' });
            return;
        }

    
        setLoading(true);
    
        try {
            const response = await axios.post(`${API_HTTP}://${API_SERVER}:${API_PORT}/${API_BASE}/denuncia`, {
                nome: Nome,
                msg: Msg,
                codemp: 1
            });
    
            if (response.status === 200) {
                setMessage({ type: 'success', msg: 'E-mail enviado com sucesso!' });
            } else {
                console.error('Erro ao enviar o e-mail.');
                setMessage({ type: 'error', msg: 'Erro ao enviar o e-mail.' });
            }
        } catch (error) {
            console.error('Erro ao enviar o e-mail:', error);
            setMessage({ type: 'error', msg: 'Erro ao enviar o e-mail.' });
        } finally {
            setLoading(false);
        }
    };


    return (
        <section className={styles.fundo}>
            <Container>
                <form className={styles.form_cansau} onSubmit={handleSubmit}>
                    <Message type={message.type} msg={message.msg} />
                    {loading && <Loading />}
                    <section>
                        <h1>Canal de Denuncia</h1>
                        <h4>
                            Se você presenciou ou sofreu qualquer tipo de violência ou assédio, relate imediatamente pelo formulário abaixo. Sua denúncia será mantida em sigilo e tratada com seriedade e respeito.
                        </h4>
                    </section>
                    <section className={styles.group}>
                        <section>
                            <Input
                                type="text"
                                text="Identificação"
                                name="name"
                                placeholder="Digite o seu nome..."
                                handleOnChange={(e) => SetNome(e.target.value)}
                                value={Nome}
                            />
                        </section>
                        <section>
                            <TextArea
                                rows={5}
                                nome="mensagem"
                                text="Mensagem"
                                placeholder="Digite uma mensagem..."
                                handleOnChange={(e) => SetMsg(e.target.value)}
                                value={Msg}
                            />
                        </section>
                        <button type="submit">Enviar</button>
                    </section>
                </form>
            </Container>
        </section>
    );
}

export default Denuncia;
