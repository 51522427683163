import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Login.module.css';
import { Alert, Button, Snackbar, TextField } from '@mui/material';
import AuthContext from '../auth/AuthContext';
import Ouvidoria from '../../img/ouvidoria.svg'

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState({ show: false, severity: '', message: '' });
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const SERVER = process.env.REACT_APP_API_SERVER;
    const PORT = process.env.REACT_APP_API_PORT;
    const HTTP = process.env.REACT_APP_API_HTTP;
    const URL_LOGIN = process.env.REACT_APP_API_LOGIN;

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const resp = await axios.post(`${HTTP}://${SERVER}:${PORT}/${URL_LOGIN}`, {
                login: username,
                senha: password
            });

            if (resp.status === 200) {
                const { token, login: loginUsername } = resp.data;

                localStorage.removeItem('token');
                localStorage.setItem('token', token);
                localStorage.removeItem('username');
                localStorage.setItem('username', loginUsername);

                login(token, loginUsername);
                navigate('/');
            }
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.error || error.response.data;
                setAlert({ show: true, severity: 'error', message: `${errorMessage}` });
            } else {
                setAlert({ show: true, severity: 'error', message: `Usuário ou senha incorretos` });
            }
        }
    }

    const handleClose = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <section className={styles.container_login}>
            <div className={styles.left}>
                <img src={Ouvidoria} alt='ouvidoria' />
            </div>
            <div className={styles.right}>
                <form onSubmit={handleLogin} className={styles.form_login}>
                    <div className="form-row">
                        <div className={styles.input_data}>
                            <TextField
                                label="Login"
                                name='login'
                                variant="standard"
                                fullWidth
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className={styles.input_data}>
                            <TextField
                                label="Senha"
                                name='senha'
                                type='password'
                                variant="standard"
                                fullWidth
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Button variant="contained" type="submit" className={styles.button_save}>
                            LOGIN
                        </Button>
                    </div>
                </form>
            </div>
            <Snackbar
                open={alert.show}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </section>
    );
}
