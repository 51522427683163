import { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import integracaoImage from '../../../img/integracao.png'
import FiliaisImage from '../../../img/Filiais.svg'
import styles from './Carrosel.module.css'
import { Button } from '@mui/material'

export default function Carrosel() {

    const [slider, setSlider] = useState(1)

    useEffect(() => {
        function handleResize() {
            setSlider(1);
        }

        handleResize();

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const listImageCarrousel = [
        { link: integracaoImage, title: "Seja bem-vindo a família Teixeira Têxtil" },
        { link: integracaoImage, title: "O que nos move são as pessoas" },
        { link: FiliaisImage, title: "Conheça todas as nossas unidades" },
    ]

    return (
        <Swiper
            slidesPerView={slider}
            pagination={{ clickable: true }}
            navigation
        >
            {listImageCarrousel.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className={styles.slide_container}>
                        <img src={item.link} alt="Imagem do carrosel" className={styles.image_carrousel} />
                        {/* <iframe src="https://www.youtube.com/embed/j5DRfgjqw6Q?si=fWne567MQLnu5Iar" className={styles.image_carrousel}></iframe> */}
                        <div className={styles.overlay}>
                            <h2>{item.title}</h2>
                            <Button variant='contained' className='primary-btn' onClick={() => window.open('https://www.teixeiratextil.com.br/')}>Conheça nosso site</Button>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
