import { useState, useEffect } from "react"

import styles from  './Message.module.css'

function Message({ type, msg }){
    
    const [ visible, setVisible ] = useState(false)

    useEffect(() => {

        if(!msg){
            setVisible(false)
            return
        }

        setVisible(true)

        const timer = setTimeout(() => {
            setVisible(false)
        }, 5000)

        return () => clearTimeout(timer)

    }, [msg])
    
    return(
        <>
            { visible && (
                <section className={`${styles.message} ${styles[type]}`}>
                    {msg}
                </section>
            )}
        </>
    )
}

export default Message