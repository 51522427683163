function TextArea({ rows, nome, text, placeholder, handleOnChange, value }) {
    return (
        <section>
            <label htmlFor={nome}>{text}:</label>
            <textarea
                rows={rows}
                id={nome}
                name={nome}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            ></textarea>
        </section>
    )
}

export default TextArea