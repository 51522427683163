import AppBar from "../layout/integracao/AppBar";
import Carrosel from "../layout/integracao/Carrosel";
import Conteudo from "../layout/integracao/Conteudo";
import Footer from "../layout/integracao/Footer";

export default function Integracao() {

    return (
        <>
            <AppBar/>
            <Carrosel />
            <Conteudo />
            <Footer />
        </>
    )
}