import { Link } from 'react-router-dom'

import styles from './Card.module.css'

function Card({ img, alt, title, text, link, target, click }) {

    return (
        <section className={styles.container_card} >
            <section>
                <img src={img} alt={alt} />
            </section>
            <section className={styles.container_content}>
                <h1>{title}</h1>
                <h4>{text}</h4>
            </section>
            <section className={styles.container_btn}>
                <Link to={link} target={target} onClick={click}>Visite</Link>
            </section>
        </section>
    )
}

export default Card