import React, { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './EditInPlace.module.css';
import AuthContext from "../../auth/AuthContext";
import QuillResizeImage from 'quill-resize-image';

// Registrar o módulo quill-resize-image no Quill
Quill.register('modules/resize', QuillResizeImage);

export default function TextArea({ value, onChangeValue, viewAs }) {
    const { isAuthenticated } = useContext(AuthContext);
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const edit = () => {
        if (isAuthenticated) {
            setIsEditing(true);
        }
    };

    const done = () => {
        onChangeValue(currentValue);
        setIsEditing(false);
    };

    const cancel = () => {
        setIsEditing(false);
        setCurrentValue(value);
    };

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const modules = {
        toolbar: {
            container: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean']
            ],
        },
        resize: {
            locale: {},
        },
    };

    if (isEditing) {
        return (
            <>
                <ReactQuill
                    value={currentValue}
                    onChange={setCurrentValue}
                    modules={modules}
                    className={styles.edit_in_place_textarea}
                />
                <div className={styles.btn_container}>
                    <Button onClick={done} className="secondary-btn">Salvar</Button>
                    <Button onClick={cancel} className="secondary-btn">Cancelar</Button>
                </div>
            </>
        );
    }

    return React.createElement(viewAs || 'div', {
        onClick: edit,
        className: 'edit-in-place',
        children: <span dangerouslySetInnerHTML={{ __html: value }} />
    });
}
