function Input({ type, text, name, placeholder, value, handleOnChange }){
    return (
        <section>
            <label htmlFor={name}>{text}:</label>
            <input 
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            />
        </section>
    )
}

export default Input