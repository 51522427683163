import { Link } from 'react-router-dom'
// import { useContext } from 'react';
// import AuthContext from '../auth/AuthContext';

import styles from './NavBar.module.css';
import Logo from '../../img/Logo.png';

function NavBar({ show }) {

    // const { isAuthenticated, logout } = useContext(AuthContext);

    // const handleLogout = (e) => {
    //     e.preventDefault();

    //     logout();
    // }

    return (
        <section className={show ? `${styles.sidenav} ${styles.active}` : styles.sidenav}>
            <img src={Logo} alt="logo" className={styles.logo} />
            <ul>
                <li>
                    <Link to="/"><i className="bi bi-house"></i>Home</Link>
                </li>
                <li>
                    <Link to="https://www.teixeiratextil.com.br/" target='_blank'><i className="bi bi-globe2"></i>Site Oficial</Link>
                </li>
                <li>
                    <Link to="/denuncia"><i className="bi bi-telephone-x"></i>Canal de denuncia</Link>
                </li>
                <li>
                    <Link to="/ouvidoria"><i className="bi bi-telephone-plus"></i>Canal de saude mental</Link>
                </li>
                <li>
                    <Link to="/integracao"><i className="bi bi-people-fill"></i>Integração</Link>
                </li>
                {/* {!isAuthenticated && (
                    <li>
                        <Link onClick={handleLogout}><i class="bi bi-box-arrow-right"></i>logout</Link>
                    </li>
                )} */}
            </ul>
        </section>
    )
}

export default NavBar