import styles from './Footer.module.css'
import CopyrightIcon from '@mui/icons-material/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import teixeira from '../../../img/teixeiratextil.svg'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const link = {
    urlInstagram: 'https://www.instagram.com/teixeiraindustria',
    urlFacebook:  'https://www.facebook.com/teixeiraindustria/?locale=pt_BR',
    urlLinkedin:  'https://www.linkedin.com/company/teixeiratextil?originalSubdomain=br',
    urlYoutube:   'https://www.youtube.com/@teixeiratextil2072',
    urlLocation:  'https://www.google.com.br/maps/place/Teixeira+T%C3%AAxtil+-+Big+Bags+e+Sacarias+de+R%C3%A1fia/@-28.7543996,-49.4937011,17z/data=!3m1!4b1!4m6!3m5!1s0x952185d246dd1169:0xb179bf79f62f9797!8m2!3d-28.7544043!4d-49.4911262!16s%2Fg%2F11c54dcmjn?entry=ttu',
}

export default function Footer() {
    return (
        <footer>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div>
                        <img src={teixeira} alt='Teixeirinha' />
                    </div>
                    <div className={styles.item_footer}>
                        <p>Contatos: </p>
                        <span> <LocalPhoneIcon /> +55 (48) 3463-8900  </span>
                        <span> <EmailIcon /> teixeiratextil@teixeiratextil.com.br  </span>
                    </div>
                    <div className={styles.item_footer}>
                        <p>Localização: </p>
                        <a href={link.urlLocation} > <LocationOnIcon /> Rua Ignes Tiscoski Herdt , 150 Santa Isabel - 88850-000 Forquilhinha - SC  </a>
                    </div>
                    <div className={styles.social_media} >
                        <a href={link.urlInstagram} target="_blank" rel="noreferrer">
                            <InstagramIcon />
                        </a>
                        <a href={link.urlFacebook} target="_blank" rel="noreferrer">
                            <FacebookIcon />
                        </a>
                        <a href={link.urlLinkedin} target="_blank" rel="noreferrer">
                            <LinkedInIcon />
                        </a>
                        <a href={link.urlYoutube} target="_blank" rel="noreferrer">
                            <YouTubeIcon />
                        </a>
                    </div>
                </div>
                <div className={styles.baseboard}>
                    <div className={styles.copy_rights}>
                        <p> <CopyrightIcon /> <span> 2024 Copyrights - Todos os direitos reservados </span> </p>
                    </div>
                    <div className={styles.dev}>
                        <p> Developed by <span>Victor Antonio</span> </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}