import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './component/layout/NavBar';
import Home from './component/Pages/Home';
import CanSau from './component/Pages/CanSau';
import Denuncia from './component/Pages/Denuncia';
import Integracao from './component/Pages/integracao';
import CustomDialog from './component/layout/Dialog';
import PrivateRoute from './component/auth/PrivateRoute';
import { AuthProvider } from './component/auth/AuthContext';
import Login from './component/Pages/Login';

import './App.css';
import LogoOuv from './img/Logo.png';

import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

register();

function App() {
  const [showNav, setShowNav] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    localStorage.clear();
    const isFirstVisit = localStorage.getItem('isFirstVisit');
    if (!isFirstVisit) {
      setShowModal(true);
      localStorage.setItem('isFirstVisit', 'true');
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Router basename='/portal'>
      <AppContent showNav={showNav} setShowNav={setShowNav} showModal={showModal} closeModal={closeModal} />
    </Router>
  );
}

function AppContent({ showNav, setShowNav, showModal, closeModal }) {
  const location = useLocation();

  const notHeaderPaths = {
    '/login': true,
    '/integracao': true,
  };

  const notHeader = notHeaderPaths[location.pathname] || false;

  return (
    <>
      {!notHeader && (
        <>
          <header className="header-app">
            <section>
              <img src={LogoOuv} alt='logo' />
              <h1>Teixeira Têxtil</h1>
            </section>
            <section>
              {showNav ? (
                <i className="bi bi-x-lg" onClick={() => setShowNav(!showNav)}></i>
              ) : (
                <i className="bi bi-list" onClick={() => setShowNav(!showNav)}></i>
              )}
            </section>
          </header>
          <NavBar show={showNav} />
        </>
      )}
      <AuthProvider>
        <section className='main'>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/integracao' element={<Integracao />} />
            <Route path='/ouvidoria' element={<CanSau />} />
            <Route path='/denuncia' element={<Denuncia />} />
            <Route path='/login' element={<Login />} />
            <Route path='/privado/*' element={<PrivateRoute><PrivateRoutes /></PrivateRoute>} />
          </Routes>
        </section>
      </AuthProvider>
      {/* {!notHeader && showModal && <CustomDialog open={showModal} onClose={closeModal} />} */}
    </>
  );
}

function PrivateRoutes() {
  return (
    <Routes>
      <Route path='integracao' element={<Integracao />} />
    </Routes>
  );
}

export default App;
