import Loader from '../../img/Loader.svg'
import styles from './Loading.module.css'

function Loading(){
    return(
        <section className={styles.loader_container}>
            <img className={styles.loader} src={Loader} alt='Loading' />
        </section>
    )
}

export default Loading
