import React, { useEffect, useState, useContext } from 'react';
import styles from './Conteudo.module.css';
import { Alert, Button, Snackbar } from '@mui/material';
import axios from 'axios';
import CadastroEditInPlace from './layout/dialog/CadastroEditInPlace';
import TextArea from './EditInPlace';
import Input from './EditInPlaceInput';
import DotMenu from './layout/dotmenu/DotMenu';
import AuthContext from '../../auth/AuthContext';

const options = [
  'Excluir',
];

export default function Integracao() {
  const { isAuthenticated } = useContext(AuthContext);
  const [selectedType, setSelectedType] = useState('Instituição');
  const [content, setContent] = useState([]);
  const [openCadastroModal, setOpenCadastroModal] = useState(false);
  const [alert, setAlert] = useState({ show: false, severity: '', message: '' });

  const SERVER = process.env.REACT_APP_API_SERVER;
  const PORT = process.env.REACT_APP_API_PORT;
  const HTTP = process.env.REACT_APP_API_HTTP;
  const BASEURL = process.env.REACT_APP_API_BASEURL;

  const getContent = async () => {
    try {
      const resp = await axios.get(`${HTTP}://${SERVER}:${PORT}/${BASEURL}/content`);
      setContent(resp.data.content);
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  useEffect(() => {
    getContent();
  }, [isAuthenticated]);

  const uniqueTypes = [...new Map(content.map((item) => [item.type, item])).values()];

  const filteredTerms = content.filter((item) => item.type === selectedType);

  const handleOpenCadastroModal = () => {
    setOpenCadastroModal(true);
  };

  const handleCloseCadastroModal = () => {
    setOpenCadastroModal(false);
    getContent();
  };

  const handleUpdateContent = async (id, newContent) => {
    try {
      await axios.put(`${HTTP}://${SERVER}:${PORT}/${BASEURL}/content/${id}`, { content: newContent });
      getContent();
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setAlert({ show: true, severity: 'error', message: `${errorMessage}` });
      } else {
        setAlert({ show: true, severity: 'error', message: `Ocorreu um erro ao enviar imagem` });
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const resp = await axios.delete(`${HTTP}://${SERVER}:${PORT}/${BASEURL}/content/${id}`);
      if (resp.status === 200) {
        setAlert({ show: true, severity: 'success', message: 'Conteúdo deletado com sucesso' });
      }
      getContent();
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setAlert({ show: true, severity: 'error', message: `${errorMessage}` });
      } else {
        setAlert({ show: true, severity: 'error', message: `Ocorreu um erro ao enviar imagem` });
      }
    }
  };

  const handleUpdateTitle = async (id, newTitle) => {
    try {
      await axios.put(`${HTTP}://${SERVER}:${PORT}/${BASEURL}/content/${id}`, { title: newTitle });
      getContent();
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setAlert({ show: true, severity: 'error', message: `${errorMessage}` });
      } else {
        setAlert({ show: true, severity: 'error', message: `Ocorreu um erro ao enviar imagem` });
      }
    }
  };

  const handleClose = () => {
    setAlert({ ...alert, show: false });
  };

  const handleOptionClick = (option, itemId) => {
    if (option === 'Excluir') {
      handleDelete(itemId);
    }
  };

  return (
    <div className={styles.conteudo}>
      {isAuthenticated && (
        <div className={styles.content_btn_open_cad_conteudo}>
          <Button variant="contained" className="primary-btn" onClick={handleOpenCadastroModal}>
            Cadastrar Novo Conteúdo
          </Button>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {uniqueTypes.map((typeObj, index) => (
          <Button
            variant="contained"
            className="primary-btn"
            key={index}
            onClick={() => setSelectedType(typeObj.type)}
          >
            {typeObj.type}
          </Button>
        ))}
      </div>

      <div className={styles.cardsContainer}>
        {filteredTerms.map((item, index) => (
          <div key={index} className={`${styles.card} ${index % 2 === 0 ? styles.evenCard : styles.oddCard}`}>
            {isAuthenticated && (
              <div className={styles.dotMenuContainer}>
                <DotMenu options={options} onOptionClick={(option) => handleOptionClick(option, item._id)} />
              </div>
            )}
            <>
              <Input
                value={item.title}
                onChangeValue={(newValue) => handleUpdateTitle(item._id, newValue)}
                viewAs="h1"
              />
              <TextArea
                value={item.content}
                onChangeValue={(newValue) => handleUpdateContent(item._id, newValue)}
                viewAs="h3"
              />
            </>
            {item.video && <iframe width="560" height="315" src={item.video} />}
          </div>
        ))}
      </div>
      <Snackbar
        open={alert.show}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>

      {isAuthenticated && <CadastroEditInPlace getAll={getContent} open={openCadastroModal} onClose={handleCloseCadastroModal} />}
    </div>
  );
}
