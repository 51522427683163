import { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from "../layout/Container"
import Card from '../Project/Card'
import imgDenuncia from '../../img/megafone.png'
import imgSaude from '../../img/imgSaude.jpg'
import imgBi from '../../img/upbi.png'
import imgOutlook from '../../img/outlook.png'
import imgIntegracao from '../../img/integracao.png'
import imgRnc from '../../img/rnc.png'
import imgSenior from '../../img/unisenior.png'
import imgWiipo from '../../img/wiipo.png'
import styles from './Home.module.css'

function Home() {

    const API_SERVER = process.env.REACT_APP_API_SERVER
    const API_PORT = process.env.REACT_APP_API_PORT
    const API_HTTP = process.env.REACT_APP_API_HTTP
    const API_BASE = process.env.REACT_APP_API_BASEURL
    const BASEURL_VISITAS = process.env.REACT_APP_API_BASEURL_VISITAS

    const [slider, setSlider] = useState(3)
    

    const logVisit = async () => {
        try {
            const response = await fetch(`${API_HTTP}://${API_SERVER}:${API_PORT}/${BASEURL_VISITAS}/visitas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tipnav: navigator.userAgent,
                })
            });

            if (!response.ok) {
                throw new Error('Erro ao registrar a visita');
            }

            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Erro ao registrar a visita:', error);
        }
    };

    useEffect(() => {
        logVisit();
    }, []);

    useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 720) {
                setSlider(1)
            } else if (window.innerWidth < 980) {
                setSlider(2)
            } else if (window.innerWidth < 1600) {
                setSlider(3)
            } else {
                setSlider(4)
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }

    }, [])

    function handleClickWiipo() {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        if (isIOS) {
            window.location.href = 'https://apps.apple.com/br/app/wiipo/id1522728600';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.wiipo';
        }
    }

    return (
        <Container>
            <section className={styles.container_cards} >

                <Swiper
                    slidesPerView={slider}
                    pagination={{ clickable: true }}
                    navigation
                >
                    <SwiperSlide>
                        <Card
                            img={imgSaude}
                            alt="img denuncia"
                            title="Canal de Saude Mental"
                            text="Um canal de ouvidoria em saúde mental é uma ferramenta vital para promover o bem-estar emocional e a qualidade dos serviços oferecidos."
                            link='ouvidoria'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgDenuncia}
                            alt="img denuncia"
                            title="Canal de denuncia"
                            text="Canal de Denúncia: Sua voz, nossa prioridade. Reporte anonimamente qualquer irregularidade, promovendo um ambiente seguro e transparente para todos."
                            link='denuncia'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgIntegracao}
                            alt="img integraçao"
                            title="Integração"
                            text="Integração admissional simplifica e otimiza o processo de contratação, proporcionando uma transição suave para novos colaboradores e garantindo eficiência no início da jornada profissional."
                            link='integracao'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgWiipo}
                            alt="img wippo"
                            title="Wiipo"
                            text="Mais agilidade e facilidade seu  holerite e informe de rendimentos 100% on-line, na palma da sua mão, baixe o APP, como foto a logo do app em anexo."
                            click={handleClickWiipo}
                            target="_blank"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgBi}
                            alt="img BI"
                            title="BI"
                            text="Business Intelligence (BI) é uma ferramenta essencial para a tomada de decisões estratégicas nas organizações, e a UP Query oferece uma solução robusta e eficiente nesse sentido. "
                            link='https://apps.teixeiratextil.com.br/bi'
                            target="_blank"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgOutlook}
                            alt="Office outlook"
                            title="E-mail"
                            text="O Outlook, da Microsoft, é uma plataforma de e-mail e colaboração líder, oferecendo eficiência e organização na gestão de comunicações empresariais"
                            link='https://outlook.office.com'
                            target="_blank"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgRnc}
                            alt="img denuncia"
                            title="RNC"
                            text="Sistema de rnc oferece uma abordagem eficiente e transparente para identificar, documentar e corrigir discrepâncias, garantindo aprimoramento contínuo e conformidade nos processos."
                            link='https://apps.teixeiratextil.com.br/rnc'
                            target="_blank"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            img={imgSenior}
                            alt="img Uni senior"
                            title="Universidade Senior"
                            text="A universidade senior é para você tirar suas duvidas quanto a plataforma ERP."
                            link='https://universidade.senior.com.br/lms/#/home'
                        />
                    </SwiperSlide>
                </Swiper>
            </section>
        </Container>
    )
}

export default Home