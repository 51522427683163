import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import styles from './CadastroEditInPlace.module.css';

export default function CadastroEditInPlace({ getAll, open, onClose }) {
    const [content, setContent] = React.useState({
        type: '',
        title: '',
        content: '',
        video: ''
    });
    const [alert, setAlert] = React.useState({ show: false, severity: '', message: '' });

    const SERVER = process.env.REACT_APP_API_SERVER;
    const PORT = process.env.REACT_APP_API_PORT;
    const HTTP = process.env.REACT_APP_API_HTTP;
    const BASEURL = process.env.REACT_APP_API_BASEURL;

    const handleSave = async (e) => {
        e.preventDefault();
        try {

            const resp = await axios.post(`${HTTP}://${SERVER}:${PORT}/${BASEURL}/content`, content);
            setContent({
                type: '',
                title: '',
                content: '',
                video: ''
            });
            if (resp.status === 200) {
                setAlert({ show: true, severity: 'success', message: 'Conteúdo cadastrado com sucesso' });
            }
            getAll();
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                setAlert({ show: true, severity: 'error', message: `${errorMessage}` });
            } else {
                setAlert({ show: true, severity: 'error', message: `Ocorreu um erro ao enviar imagem` });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContent({ ...content, [name]: value });

    };

    const handleClose = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Cadastrar um novo conteúdo"}
            </DialogTitle>
            <DialogContent>
                <form className={styles.form_cad_content}>
                    <div className="form-row">
                        <div className="input-data">
                            <TextField
                                id="standard-basic"
                                label="Digite a área"
                                variant="standard"
                                inputProps={{ maxLength: 25 }}
                                required
                                name='type'
                                value={content.type}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-data">
                            <TextField
                                id="standard-basic"
                                label="Título"
                                variant="standard"
                                required
                                name='title'
                                value={content.title}
                                onChange={handleChange}
                                inputProps={{ maxLength: 50 }}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-data">
                            <TextField
                                id="standard-basic"
                                label="Digite o link do vídeo"
                                variant="standard"
                                name='video'
                                value={content.video}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-data">
                            <TextField
                                id="standard-basic"
                                label="Conteúdo"
                                variant="standard"
                                required
                                multiline
                                name='content'
                                value={content.content}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} className="secondary-btn">Cancelar</Button>
                <Button onClick={handleSave} autoFocus className='primary-btn'>
                    Criar
                </Button>
            </DialogActions>
            <Snackbar
                open={alert.show}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

        </Dialog>
    );
}
