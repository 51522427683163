import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import styles from './EditInPlaceInput.module.css'

export default function Input({ value, onChangeValue, viewAs }) {
    const inputRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const edit = () => setIsEditing(true);
    const done = () => {
        onChangeValue(currentValue);
        setIsEditing(false);
    };
    const cancel = () => {
        setIsEditing(false);
        setCurrentValue(value);
    };

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    if (isEditing) {
        return (
            <>
                <input
                    type="text"
                    ref={inputRef}
                    value={currentValue}
                    onChange={(e) => setCurrentValue(e.target.value)}
                    className={styles.edit_in_place_input}
                />
                <div>
                    <Button onClick={done} className="secondary-btn">Salvar</Button>
                    <Button onClick={cancel} className="secondary-btn">Cancelar</Button>
                </div>
            </>
        );
    }

    return React.createElement(viewAs || 'div', {
        onClick: edit,
        // className: 'edit-in-place',
        children: value
    });
}
